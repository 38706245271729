div[class^="MuiDataGrid"][role="rowgroup"] > div {
    cursor: pointer;
}

div.filesentstate {
    margin:auto;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

div.filesentstate.state-0 { background-color: red; }
div.filesentstate.state-1 { background-color: green; }
div.filesentstate.state-2 { background-color: orange; }